import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useHover } from 'react-use-gesture'
import { Link } from '@/components/Link'
import { Img } from '@/components/Img'
import { Bg } from '@/components/Bg'
import animations from './Thumb.animations'
import * as styles from './Thumb.styles.scss'

const Thumb = ({ href, color, image, title }) => {
  const [hoverState, setHoverState] = useState('initial')

  const handleHover = (event) => {
    if (event.hovering) {
      setHoverState('mouseOver')
    } else {
      setHoverState('mouseLeave')
    }
  }

  const bindHover = useHover((state) => handleHover(state), {})

  return (
    <motion.div
      className={styles.el}
      initial="initial"
      animate={hoverState}
      onAnimationComplete={() => {
        if (hoverState === 'mouseLeave') {
          setHoverState('initial')
        }
      }}
      {...bindHover()}
    >
      <Link to={href} className={styles.inner}>
        <motion.div className={styles.back} variants={animations.back}>
          <Bg color={color} className={styles.bg__inner} />
        </motion.div>
        {image && image.src && (
          <motion.div className={styles.middle} variants={animations.image}>
            <Img {...image} fillContainer objectFit="cover" />
          </motion.div>
        )}
        <motion.div className={styles.front} variants={animations.front}>
          <Bg color={color} className={styles.bg__inner} />
          <div className={styles.text}>{title}</div>
        </motion.div>
      </Link>
    </motion.div>
  )
}

Thumb.defaultProps = {
  title: undefined,
  image: undefined,
  color: undefined,
}

Thumb.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  image: PropTypes.object,
  color: PropTypes.string,
}

export { Thumb }
