import React from 'react'
import PropTypes from 'prop-types'
import { Bg } from '@/components/Bg'
import { Section } from '@/components/Section'
import { Container } from '@/components/Container'
import * as styles from './Reviews.styles.scss'

const Reviews = ({ items }) => {
  return (
    <Section>
      <Container>
        <div className={styles.items}>
          {items.map((item) => (
            <Bg color={item.color} className={styles.item} key={item.id}>
              <div className={styles.item__inner}>
                <div className={styles.item__quote}>{item.quote}</div>
                {item.stars !== 0 && (
                  <div className={styles.item__stars}>
                    {'★'.repeat(item.stars)}
                  </div>
                )}
                <div className={styles.item__source}>{item.source}</div>
              </div>
            </Bg>
          ))}
        </div>
      </Container>
    </Section>
  )
}

Reviews.propTypes = {
  items: PropTypes.arrayOf(PropTypes.PropTypes.object),
}

Reviews.defaultProps = {
  items: [],
}

export { Reviews }
