import React from 'react'
import PropTypes from 'prop-types'
import { Fader } from '@/components/Fader'
import { Img } from '@/components/Img'
import { Container } from '@/components/Container'
import { Section } from '@/components/Section'
import * as styles from './Image.styles.scss'

const Image = ({ images }) => {
  let isLandscape = true
  if (images.length && images[0].image) {
    isLandscape = images[0].image.aspectRatio > 1
  }

  return (
    <Section>
      <Container>
        <div className={isLandscape ? null : styles.smallWidth}>
          <Fader controls={!!images.length > 1} autoplay autoplayDelay={3000}>
            {images.map((item) => {
              return <Img key={item.id} {...item.image} />
            })}
          </Fader>
        </div>
      </Container>
    </Section>
  )
}

Image.defaultProps = {
  images: [],
}

Image.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      credit: PropTypes.node,
      image: PropTypes.object,
    })
  ),
}

export { Image }
