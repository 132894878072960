const animations = {
  front: {
    initial: {
      rotate: -10,
      y: 0,
    },
    mouseOver: {
      rotate: -5,
      y: 70,
    },
  },
  image: {
    initial: {
      rotate: -3,
      y: 0,
    },
    mouseOver: {
      rotate: -3,
      y: -70,
    },
  },
  back: {
    initial: {
      rotate: 5,
      x: 0,
    },
    mouseOver: {
      rotate: 2,
      x: -40,
    },
  },
}

export default animations
