import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Bg.styles.scss'

const Bg = ({ children, className, color, ...attributes }) => {
  return (
    <div
      className={classNames(styles.Bg, className)}
      data-color={color}
      {...attributes}
    >
      {children}
    </div>
  )
}

Bg.defaultProps = {
  children: undefined,
  className: undefined,
  color: 'yellow',
}

Bg.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
}

export { Bg }
