import React from 'react'
import PropTypes from 'prop-types'
import { Section } from '@/components/Section'
import { Thumb } from '@/components/Thumb'
import { Container } from '@/components/Container'
import * as styles from './PageLinks.styles.scss'

const PageLinks = ({ pages }) => {
  return (
    <Section>
      <Container>
        <div className={styles.thumbs}>
          {pages.map((page) => {
            return (
              <div key={page.id} className={styles.item}>
                <Thumb
                  title={page.title}
                  href={page.path}
                  image={page.image}
                  color={page.color}
                />
              </div>
            )
          })}
        </div>
      </Container>
    </Section>
  )
}

PageLinks.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.PropTypes.object),
}

PageLinks.defaultProps = {
  pages: [],
}

export { PageLinks }
