import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { Container } from '@/components/Container'
import { Section } from '@/components/Section'
import * as styles from './Video.styles.scss'

const Video = ({ url }) => {
  return (
    <Section>
      <Container>
        <div className={styles.inner}>
          <div className={styles.player}>
            <ReactPlayer url={url} controls={true} width="100%" height="100%" />
          </div>
        </div>
      </Container>
    </Section>
  )
}

Video.defaultProps = {
  url: undefined,
}

Video.propTypes = {
  url: PropTypes.string,
}

export { Video }
