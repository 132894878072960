import React from 'react'
import PropTypes from 'prop-types'
import { Img } from '@/components/Img'
import { Container } from '@/components/Container'
import { Section } from '@/components/Section'
import * as styles from './SmallImage.styles.scss'

const SmallImage = ({ image }) => {
  return (
    <Section className={styles.SmallImage}>
      <Container>
        <div className={styles.image}>
          <Img {...image} />
        </div>
      </Container>
    </Section>
  )
}

SmallImage.defaultProps = {
  image: undefined,
}

SmallImage.propTypes = {
  image: PropTypes.object,
}

export { SmallImage }
