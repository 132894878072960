import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Section } from '@/components/Section'
import * as styles from './BodyText.styles.scss'

const BodyText = ({ children }) => (
  <Section>
    <Container className={styles.container}>
      {!!children && (
        <div className={styles.content}>
          <RichText>{children}</RichText>
        </div>
      )}
    </Container>
  </Section>
)

BodyText.defaultProps = {
  children: undefined,
}

BodyText.propTypes = {
  children: PropTypes.node,
}

export { BodyText }
