import { getRichText } from './getRichText'
import { getAspectRatio } from './getAspectRatio'
// import { getLink } from './getLink'
import { getSlug } from '@/utils/getSlug'
import { get } from '@/utils/get'
import { getImage } from './getImage'
import { linkResolver } from './linkResolver'

/**
 * Process prismic slice data that can be passed to components as props
 *
 * @param {array} data
 * @param {object} location
 */

export const sectionSerializer = (data, theme = 'default') => {
  const sections = []

  data.forEach((slice, sliceIndex) => {
    if (slice) {
      // section object
      const section = {
        type: slice.slice_type,
        id: slice.id,
      }

      section.id = `section-${sliceIndex}`

      // restructure 'primary' object prop to root
      if (slice.primary) {
        Object.keys(slice.primary).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(slice.primary, key)) {
            slice[key] = slice.primary[key]
          }
        })
        delete slice.primary
      }

      // big image ---------------------
      if (slice.slice_type === 'big_image') {
        section.images = []

        slice.items.forEach((item, index) => {
          const image = getImage(item, 'image', 'fluid')
          if (image.srcset) {
            section.images.push({
              id: `${sliceIndex}-big-image-${index}`,
              image,
              credit: getRichText(item, 'credit.raw'),
            })
          }
          return {}
        })
      }

      // small image ---------------------
      else if (slice.slice_type === 'small_image') {
        section.image = getImage(slice, 'image', 'fluid')
      }

      // body text ---------------------
      else if (slice.slice_type === 'body_text') {
        section.children = getRichText(slice, 'content.raw')
      }

      // reviews ----------------------
      else if (slice.slice_type === 'reviews') {
        section.items = []

        slice.items.forEach((item, index) => {
          // https://stackoverflow.com/a/4009768
          const stars = get(item, 'stars') || ''
          const starsCount = (stars.match(/★/g) || []).length

          const color = theme || 'default'
          const isEven = index % 2 === 0
          const secondaryColors = {
            default: 'orange',
            yellow: 'orange',
            orange: 'green',
            green: 'orange',
            pink: 'purple',
            purple: 'pink',
          }
          const altColor = secondaryColors[color]

          section.items.push({
            id: `${sliceIndex}-review-${index}`,
            quote: getRichText(item, 'quote.raw'),
            stars: starsCount,
            source: getRichText(item, 'source.raw'),
            color: isEven ? color : altColor,
          })
        })
      }

      // text accordion ----------------
      else if (slice.slice_type === 'text_accordion') {
        section.heading = getRichText(slice, 'heading', 'p')
        section.bodyText = getRichText(slice, 'body_text.raw')

        section.items = []
        slice.items.forEach((item, index) => {
          section.items.push({
            id: `${sliceIndex}-text-accordion-${index}`,
            heading: getRichText(item, 'heading', 'p'),
            content: getRichText(item, 'content.raw'),
          })
        })
      }

      // text and image ----------------
      else if (slice.slice_type === 'text_and_image') {
        section.heading = getRichText(slice, 'heading', 'p')
        section.bodyText = getRichText(slice, 'body_text.raw')
        section.layout = get(slice, 'layout')
        /* if (get(slice, 'cta_label') && get(slice, 'link.url')) {
          section.cta = {
            label: s.cta_label,
            url: s.link.url,
            target: s.link.target,
          }
        } */
        if (get(slice, 'video.url')) {
          section.video = {
            url: slice.video.url,
          }
        }
        section.images = []
        slice.items.forEach((item, index) => {
          const image = getImage(item, 'image', 'fluid')
          section.images.push({
            ...image,
            id: `${sliceIndex}-text-and-image-${index}`,
          })
        })
      }

      // links
      else if (slice.slice_type === 'page_links') {
        section.pages = []
        slice.items.forEach((item, index) => {
          section.pages.push({
            image: getImage(item, 'page_link.document.data.image', 'fluid'),
            title: item.page_link.document.data.title,
            path: linkResolver({
              uid: get(item, 'page_link.document.uid'),
              type: get(item, 'page_link.document.type'),
              parent: get(item, 'page_link.document.data.parent.uid'),
            }),
            color: getSlug(item.page_link.theme),
            id: `${sliceIndex}-page-links-${index}`,
          })
        })
      }

      // video
      else if (slice.slice_type === 'video') {
        section.url = slice.url
        section.aspectRatio = getAspectRatio(slice.aspect_ratio)
      }

      // add to output array
      sections.push(section)
    }
  })

  return sections
}
